import { VFC } from "react";
import { YaliState } from "../../ducks/yali";
import Mouth from "./Mouth";

type Props = {
  mood: string;
  yali: YaliState;
};

const Yali: VFC<Props> = (props) => {
  const { yali } = props;

  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 284.04 325.15"
    >
      <path
        d="M198.43,63.16c11.79-17.2,26.53-32.9,46.12-40s41.44-7.25,61.53-1.67c7.12,2,14.12,4.69,20.19,8.92,11.7,8.13,19.07,21.28,23.3,34.89,5.32,17.11.84,30.71,3.39,40.57,2.86,11.09,19.69,29.57,24.75,38.57,7.66,13.62,13.5,28.18,18.74,42.9,8.86,24.85,16.07,52.08,8.32,77.3-8.5,27.65-33.6,47.29-60.23,58.58C299.87,342.15,248,342,202.35,325.57c-11.61-4.19-23-9.47-32.49-17.35-18.55-15.41-28-39.13-34-62.5-6.26-24.64-9.39-50.58-4.31-75.49,4.5-22.06,21.21-51.65,44.43-61.35a17.74,17.74,0,0,0,10.51-13C188.46,86.17,192.47,71.85,198.43,63.16Z"
        transform="translate(-126.51 -15.03)"
        fill="#39b54a"
      />
      <path
        d="M200.59,64.42C209,52.26,218.82,40.69,231.3,32.59c13.58-8.82,29.95-12.56,46-12.57a104.39,104.39,0,0,1,25.43,3.16c8,2,15.88,4.83,22.64,9.64,12.09,8.59,19.78,23.43,23,37.65,1.66,7.4,1.53,14.92,1.31,22.46-.17,5.91-.16,11.76,2.19,17.29s5.79,10.43,9.23,15.23c3.67,5.12,7.57,10.06,11.19,15.21a119.73,119.73,0,0,1,10,18.24c3.79,8.09,7.09,16.39,10.16,24.78,5.89,16.08,11.35,32.71,12.74,49.88,1.29,15.87-1.39,31.65-9.59,45.42-7.17,12-17.62,21.91-29.22,29.65-26.18,17.49-58.44,25.65-89.68,26.47a200.55,200.55,0,0,1-49.15-4.79c-15.87-3.56-32.05-8.66-46.11-17-13.31-7.89-23.27-19.46-30.39-33.11s-11.49-28.62-14.78-43.59c-3.85-17.45-5.84-35.43-4.16-53.27a100.26,100.26,0,0,1,12-39.26c6.35-11.52,14.93-22.5,26.25-29.48,2.73-1.68,5.69-2.8,8.49-4.32a20.15,20.15,0,0,0,7.54-7.24c1.83-3,2.45-6.27,3.2-9.64s1.64-6.81,2.66-10.15c2-6.56,4.45-13.13,8.32-18.83,1.81-2.67-2.52-5.18-4.32-2.53-4.18,6.17-6.83,13.39-9,20.49q-1.56,5.19-2.7,10.51c-.71,3.27-1.37,6.34-3.48,9s-4.84,4-7.86,5.43a52.49,52.49,0,0,0-8.54,5,75.32,75.32,0,0,0-15,14.39A105.9,105.9,0,0,0,130.82,163c-4.78,16.34-5.1,34-3.31,50.86A222.41,222.41,0,0,0,139,264.65c5.16,14.51,12.35,28.57,23.11,39.75,11.62,12.08,27,19.28,42.61,24.64a207.54,207.54,0,0,0,49.66,10.4c33.35,2.84,67.88-2.49,98.13-17.11,26.24-12.69,50.5-34.32,56.45-64,3.5-17.44.84-35.42-3.87-52.37-2.43-8.71-5.4-17.26-8.55-25.74s-6.58-16.92-10.49-25.12A116.66,116.66,0,0,0,375.8,137c-3.82-5.3-7.86-10.44-11.61-15.79-3.23-4.6-6.77-9.53-8.5-14.92-1.87-5.79-1-12.26-.88-18.22a74.43,74.43,0,0,0-3.27-24.82,80.36,80.36,0,0,0-9.85-20.52,55.1,55.1,0,0,0-16.88-16.25c-7.31-4.39-15.64-7-23.93-8.87-17.19-3.76-35.41-3.54-52.31,1.58-15,4.55-27.82,13.56-38.41,25.06a157.61,157.61,0,0,0-13.89,17.65C194.44,64.55,198.77,67.05,200.59,64.42Z"
        transform="translate(-126.51 -15.03)"
        fill="#000"
      />
      <path
        d="M251.45,104.79a39.42,39.42,0,0,1-17,4.14c-3.14-.05-9.57-2.5-12.09-4.36a12.91,12.91,0,0,1-3.61-4.69c-2.3-4.48-3.59-9.77-2-14.55,1.79-5.49,7-9.25,12.54-10.94a24.78,24.78,0,0,1,17.68,1C252.38,78,257.53,83,257.78,89,258,93.28,256.47,102.32,251.45,104.79Z"
        transform="translate(-126.51 -15.03)"
        fill="#fff"
      />
      <path
        d="M313,108.54a39.55,39.55,0,0,1-17,4.13c-3.14,0-9.57-2.5-12.09-4.36a12.83,12.83,0,0,1-3.61-4.69c-2.3-4.47-3.59-9.77-2-14.55,1.79-5.48,7-9.25,12.54-10.93a24.73,24.73,0,0,1,17.68,1c5.45,2.6,10.6,7.63,10.85,13.66C319.46,97,318,106.06,313,108.54Z"
        transform="translate(-126.51 -15.03)"
        fill="#fff"
      />

      <Mouth mood={yali.mood} />

      <circle
        cx="110.4"
        cy="76.59"
        r="2.95"
        fill="#231f20"
        stroke="#231f20"
        strokeMiterlimit="10"
        strokeWidth="8"
      />
      <circle
        cx="171.91"
        cy="79.54"
        r="2.95"
        fill="#231f20"
        stroke="#231f20"
        strokeMiterlimit="10"
        strokeWidth="8"
      />
    </svg>
  );
};

export default Yali;
